import { green, white, brand } from 'src/styles/colors'
import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  background-color: ${brand.claryOrange};
  padding-top: 56px;
  padding-bottom: 56px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .btn {
    height: 48px;
    margin-top: 32px;

    @media ${device.desktopLG} {
      margin-top: 48px;
    }
    @media ${device.desktopXL} {
      margin-top: 32px;
    }
  }

  .color-orange-argila {
    color:#943D15
  }
`

export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${green[500]};
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${white};
  }
`

export const Vantagens = styled.div`
  margin: 32px 0;

  div:last-child {
    margin-bottom: 0 !important;
  }
`

export const Vantagem = styled.div`
  margin-bottom: 12px;
  @media ${device.desktopLG} {
    margin-bottom: 30px;
  }
`

export const IconCircle = styled.div`
  background-color: ${white};
  border-radius: 50%;
  width: 40px;
  height: 40px;

  color: ${brand.claryOrange}
`
