import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import FAQ from 'src/components/Faq/index'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

interface IFaqSection {
  faq: {
    question: string;
    answer: string;
  }[];
}

const FaqSection = ({ faq }: IFaqSection) => {
  const width = useWidth(300)

  return (
    <SectionComponent
      id='perguntas-frequentes'
      SectionStyles={width < WIDTH_MD ? 'bg-grayscale--100' : '' }
    >
      <div className='col-12'>
        <h2 className='fs-24 lh-30 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 text-grayscale--500 mb-md-4 text-center font-citrina'>
          Dúvidas frequentes
        </h2>
      </div>
      <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} searchBg={width < WIDTH_MD ? '#fff' : '#F5F6FA' } />
    </SectionComponent>
  )
}

export default FaqSection
