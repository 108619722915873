import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: ${green[500]};
  padding-top: 56px;
  padding-bottom: 16px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/folha-hero-mobile/image.webp');
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .btn {
    height: 48px;
  }

  h1{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-weight: 500;

    margin-bottom: 16px;
    color: #fff;

    line-height: 28.8px;
    font-size: 24px;
  }

  p{
    width: 390px;
  }

  @media ${device.tablet} {
    height: 510px;
    padding-top: 0;
    padding-bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
 }

  @media ${device.desktopLG} {
    height: 580px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/empresas-folha-hero/image.webp');
  
    h1{
      font-size: 40px;
      line-height: 48px
    }
    p{
      width: 340px;
    }
  }

  @media ${device.desktopXL} {
    height: 840px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/empresas-folha-hero/image.webp');
  
    h1{
      font-size: 48px;
      line-height: 57.6px;
    }
  }

  .buttons-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
`
