import styled from 'styled-components'
import { brand } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  background-color: ${brand.sand};

  padding-top: 56px;
  padding-bottom: 56px;

  .btn-div {
    a {
      height: 48px;
    }

    @media ${device.tablet} {
      padding-top: 24px;
    }
    @media ${device.desktopLG} {
      padding-top: 32px;
    }
    @media ${device.desktopXL} {
      padding-top: 40px;
    }
  }


  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  p, h2{
    color: #161616;
  }
`

export const Vantagens = styled.div`
  margin: 32px 0;
  div:last-child {
    margin-bottom: 0 !important;
  }

  @media ${device.tablet} {
    margin: 0;
    height: 100%;
  }
`

export const Vantagem = styled.div``

export const IconCircle = styled.div`
  background-color: #F8DAC6;
  border-radius: 50%;
  width: 32px;
  height: 32px;
`
