import React from 'react'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'

import * as S from './styles'
import { IFolhaDePagamentoProps } from '../../types'

const Hero = ({ sendDatalayerEvent, utmLink }: IFolhaDePagamentoProps) => {
  return (
    <S.Section
      className='d-flex align-items-center'
      role='img'
      aria-label='Mulher, sentada no braço de um sofá, sorrindo enquanto usa seu notebook.'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-11 col-md-5 col-xl-4'>
            <h1>Simplifique sua folha de pagamento no Inter Empresas</h1>
            <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 fw-400 text-inter text-white'>
              Ganhe muito mais tempo na hora de realizar o pagamento de seus colaboradores.
            </p>
            <div className='buttons-container'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name: 'Simplifique sua folha de pagamento no Inter Empresas',
                    element_name: 'Abrir Conta PJ',
                    element_action: 'click button',
                    redirect_url: utmLink,
                  })
                }}
                href={utmLink}
                target='_blank'
                rel='noreferrer'
                className='text-white rounded-3 fs-18 fw-600 text-none mw-100'
              >
                Abrir Conta PJ
                <IcChevronRight height={24} width={24} color='#FFF' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
